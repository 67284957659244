import { lazy } from "react";
import Loadable from "../../components/Loadings/Loadable";

//Perigigi

const MainLayout = Loadable(
  lazy(() => import("../../components/Layouts/MainLayout"))
);

// Auths
const SignInPage = Loadable(lazy(() => import("../../screens/auths/SignIn")));

//Register
const RegisterPage = Loadable(
  lazy(() => import("../../screens/auths/Register"))
);

//Home
const HomePage = Loadable(lazy(() => import("../../screens/mains/home")));

//Patients
const PatientsPage = Loadable(
  lazy(() => import("../../screens/mains/patients"))
);

const AddPatientsPage = Loadable(
  lazy(() => import("../../screens/mains/patients/fragments/add_patient"))
);

const DetailPatientPage = Loadable(
  lazy(() => import("../../screens/mains/patients/fragments/detail_patient"))
);

//Create
const CreatePage = Loadable(lazy(() => import("../../screens/mains/create")));

const CreateExaminationPage = Loadable(
  lazy(() => import("../../screens/mains/create/fragments/create_examination"))
);
const CreatePrescriptionPage = Loadable(
  lazy(() => import("../../screens/mains/create/fragments/create_prescription"))
);
const CreatePlanTreatmentPage = Loadable(
  lazy(() =>
    import("../../screens/mains/create/fragments/create_plan_treatment")
  )
);
const CreateAppointmentPage = Loadable(
  lazy(() => import("../../screens/mains/create/fragments/create_appointment"))
);

//Payment
const PaymentPage = Loadable(lazy(() => import("../../screens/mains/payment")));

//Agenda
const AgendaPage = Loadable(lazy(() => import("../../screens/mains/agenda")));

// Account Page
const AccountPage = Loadable(lazy(() => import("../../screens/mains/account")));

const TreatmentPage = Loadable(
  lazy(() => import("../../screens/mains/account/Treatment"))
);

const TemplatePage = Loadable(
  lazy(() => import("../../screens/mains/account/Template"))
);

//Perigigi
const appRoutes = [
  {
    path: "auths",
    children: [
      { path: "sign-in", element: <SignInPage /> },
      { path: "register", element: <RegisterPage /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
    ],
  },
  {
    path: "/patients",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <PatientsPage />,
      },
      {
        path: "add-patient",
        element: <AddPatientsPage />,
      },
      {
        path: ":id",
        element: <DetailPatientPage />,
      },
    ],
  },
  {
    path: "/create",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <CreatePage />,
      },
      {
        path: "create-examination",
        element: <CreateExaminationPage />,
      },
      {
        path: "create-prescription",
        element: <CreatePrescriptionPage />,
      },
      {
        path: "create-plan-treatment",
        element: <CreatePlanTreatmentPage />,
      },
      {
        path: "create-appointment",
        element: <CreateAppointmentPage />,
      },
    ],
  },
  {
    path: "/payment",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <PaymentPage />,
      },
    ],
  },
  {
    path: "/agenda",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <AgendaPage />,
      },
    ],
  },
  {
    path: "/account",
    element: <MainLayout />,
    children: [
      {
        path: "profile",
        element: <AccountPage />,
      },
      {
        path: "treatment",
        element: <TreatmentPage />,
      },
      {
        path: "template",
        element: <TemplatePage />,
      },
    ],
  },
];

export default appRoutes;
